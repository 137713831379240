@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');


@media (max-width: 1024px) {
    .about {
        width: 90%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: #f2f5d3; */
        background-color: #f8f8f8;
        /* border: 2px solid blue; */
    }

    .about-div-avatar {
        display: none;
    }

    .text-container-about {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        /* border: 2px solid red; */
    }

    .about-h1 {
        font-family: "Lato", sans-serif;
        font-size: 2.3em;
        font-weight: 700;
        color: #9faeff;
        text-shadow: 2px 1px 0px rgba(0, 0, 0, 1);
        letter-spacing: 4px;
        width: fit-content;
    }

    .about-p {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-top: 0;
        width: 100%;
        text-align: justify;
    }

    .about-btns {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        gap: 20px;
    }

    /* .about-skills-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
        padding-bottom: 20px;
    } */

    .about-h2 {
        font-family: "Lato", sans-serif;
        font-size: 2.5em;
        font-weight: 700;
        color: #9faeff;
        text-shadow: 2px 1px 0px rgba(0, 0, 0, 1);
        letter-spacing: 4px;
        width: fit-content;
    }
    
    .about-p {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 20px;
        margin-top: 0;
        width: 100%;
    }
    
    .about-skills-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
        padding-bottom: 20px;
    }
    
    .skill-card {
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 8px;
        text-align: center;
        width: 80px;
        height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: transform 0.3s, box-shadow 0.3s;
        background-color: #fff;
    }
    
    .skill-card:hover {
        transform: translateY(-10px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
    
    .skill-icon {
        width: 45px;
        margin: 0;
        transition: transform 0.3s;
    }
    
    .skill-card:hover .skill-icon {
        transform: scale(1.1);
    }
    
    .skill-name {
        font-weight: bold;
        margin: 0;
        font-size: 10px;
        transition: color 0.3s;
    }
    
    .skill-card:hover .skill-name {
        color: #000;
    }
    
    .skill-description {
        font-size: 0.6em !important;
        color: #666;
        /* margin: 0; */
        transition: color 0.3s;
        font-family: "Lato", sans-serif;
        text-align: center;
        letter-spacing: 1px;
        font-weight: 800;
    }
    
    .skill-card:hover .skill-description {
        color: #000;
    }
}


@media (min-width: 1025px) {
.about {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-color: #f2f5d3; */
    background-color: #f8f8f8;
}

.about-div-avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    /* border: 2px solid blue; */
}

.about-image-avatar {
    width: 70%;
}

.text-container-about {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* border: 2px solid red; */
}

.about-h1 {
    font-family: "Lato", sans-serif;
    font-size: 3.5em;
    font-weight: 700;
    color: #9faeff;
    text-shadow: 3px 2px 0px rgba(0, 0, 0, 1);
    letter-spacing: 4px;
    width: fit-content;
    width: 100%;
    text-align: start;
}

.about-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.about-h2 {
    font-family: "Lato", sans-serif;
    font-size: 3.5em;
    font-weight: 700;
    color: #9faeff;
    text-shadow: 3px 2px 0px rgba(0, 0, 0, 1);
    letter-spacing: 4px;
    width: 100%;
    text-align: center;
}

.about-p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 20px;
    margin-top: 0;
    width: 96%;
    align-self: flex-start;
    text-align: justify;
}

.about-skills-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    padding-bottom: 20px;
    /* border: 2px solid red; */
}

.skill-card {
    border: 2px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    width: 80px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.skill-description {
    font-size: 0.6em !important;
    color: #666;
    transition: color 0.3s;
    font-family: "Lato", sans-serif;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 800;
    margin-top: 10px !important;
}

.skill-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.skill-icon {
    width: 45px;
    margin: 0;
    transition: transform 0.3s;
}

.skill-card:hover .skill-icon {
    transform: scale(1.1);
}

.skill-name {
    font-weight: bold;
    margin: 0;
    font-size: 10px;
    transition: color 0.3s;
}

.skill-card:hover .skill-name {
    color: #000;
}

.skill-description {
    font-size: 0.8em;
    color: #666;
    margin: 0;
    font-size: 12px;
    transition: color 0.3s;
}

.skill-card:hover .skill-description {
    color: #000;
}
}