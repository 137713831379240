.btn41-43 {
    padding: 20px 35px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 2px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: none !important;
  }
  
  .btn-43 {
    border: 2px solid rgb(0, 0, 0) !important;
    z-index: 1;
    color: #000000 !important;
    background-color: transparent !important;
  }
  
  .btn-43:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    background: #92e3a9;
    transition: all 0.3s ease;
  }
  
  .btn-43:hover {
    color: rgb(0, 0, 0) !important;
  }
  
  .btn-43:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  