.custom-carousel .p-carousel-prev,
.custom-carousel .p-carousel-next {
  background: rgba(159, 174, 255, 0.6);
  color: #000;
  border: 1px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease;
}

@media (max-width: 1024px) {
    .team {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: #f2f5d3; */
        background-color: #f8f8f8;
        padding-bottom: 100px;
        /* border: 2px solid red; */
    }

    .team-h1 {
        font-family: "Lato", sans-serif;
        font-size: 2.5em;
        font-weight: 700;
        color: #9faeff;
        text-shadow: 2px 1px 0px rgba(0, 0, 0, 1);
        letter-spacing: 4px;
        width: 100%;
        text-align: center;
        /* border: 2px solid red; */
        margin-bottom: 0;
    }

    .team-cards {
        display: flex;
        align-items: center;
        justify-content: center;
        /* gap: 5rem; */
        width: 100%;
        /* border: 2px solid blue; */
        perspective: 1000px;
    }
    
    .team-card-item {
        width: 240px;
        /* height: 500px; */
        position: relative;
        perspective: 1000px;
        cursor: pointer;
        transform: 0.6s;
        margin: 0;
        /* border: 2px solid red; */
        left: -10%;
    }
    
    .card-face {
        position: absolute;
        width: 100%;
        backface-visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 2px solid green; */
    }
    
    .card-back {
        transform: rotateY(180deg);
    }

    .image-front, .image-back {
        width: 99%;
        object-fit: cover;
        /* border: 2px solid blue; */
        margin-top: -10%;
    }

    .custom-carousel .p-carousel-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .custom-carousel {
        width: 100%;
        height: 100%;
        /* border: 2px solid green; */
      }

      /* .custom-carousel-content {
        border: 2px solid blue;
      } */

      .p-carousel-item {
        height: 500px;
        /* border: 2px solid red; */
        /* width: 220px !important; */
        padding: 0px;

      }

      .flip-card {
        background-color: transparent;
        width: 200px; /* Ajuste conforme necessário */
        height: 300px; /* Ajuste conforme necessário */
        perspective: 1000px; /* Adiciona perspectiva para o efeito 3D */
        cursor: pointer;
        /* border: 2px solid red; */
        /* margin: 0; */
      }
      
      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        
      }
      
      .flip-card.flipped .flip-card-inner {
        transform: rotateY(180deg);
      }
      
      .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
      }
      
      .flip-card-front {
        background-color: #f0f0f0; /* Ajuste conforme necessário */
        
      }
      
      .flip-card-back {
        background-color: #ffffff; /* Ajuste conforme necessário */
        transform: rotateY(180deg);
      }

      .p-carousel-indicators .p-carousel-indicator button {
        background-color: #1d1b1b;
        width: 2rem;
        height: 0.25rem;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 0;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        /* margin-top: -1rem; */
        opacity: 95%;
    }

    .p-carousel-indicators .p-carousel-indicator button {
        background: #495057;
    }
    
    .p-carousel-indicators .p-carousel-indicator button:hover {
      background: rgba(159, 174, 255, 0.6);
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background: rgba(159, 174, 255, 0.6);
      color: #495057;
    }

    .team-container-cards {
        width: 90%;
      }
}


@media (min-width: 1025px) {
    .team {
        width: 90%;
        height: 100vh; /* Ajusta a altura para ocupar a altura total da viewport */
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #f8f8f8;
        /* border: 2px solid green; */
        overflow: hidden; /* Garante que o conteúdo não saia do contêiner */
    }
    
    .team-h1 {
        font-family: "Lato", sans-serif;
        font-size: 3.5em;
        font-weight: 700;
        color: #9faeff;
        text-shadow: 3px 2px 0px rgba(0, 0, 0, 1);
        letter-spacing: 4px;
        width: 100%; /* Ajusta para ocupar toda a largura disponível */
        text-align: center;
        margin-bottom: 0;
    }

    .team-cards {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5rem;
        width: 100%;
        /* border: 5px solid red; */
        perspective: 1000px;
    }
    
    .team-card-item {
        width: 220px;
        /* height: 500px; */
        position: relative;
        perspective: 1000px;
        cursor: pointer;
        transform: 0.6s;
        /* margin: 0 20px 20px 20px; */
    }
    
    .card-face {
        position: absolute;
        width: 100%;
        backface-visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card-back {
        transform: rotateY(180deg);
        border: 2px solid red;
    }

    .image-front, .image-back {
        width: 100%;
        object-fit: cover;
    }

    .custom-carousel .p-carousel-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .custom-carousel {
        width: 75%;
        height: 600px;
        /* border: 2px solid blue; */
      }

      .p-carousel-item {
        height: 450px;
        /* border: 2px solid red; */
      }

      .flip-card {
        background-color: transparent;
        width: 200px; /* Ajuste conforme necessário */
        height: 300px; /* Ajuste conforme necessário */
        perspective: 1000px; /* Adiciona perspectiva para o efeito 3D */
        cursor: pointer;
      }
      
      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }
      
      .flip-card.flipped .flip-card-inner {
        transform: rotateY(180deg);
      }
      
      .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
      }
      
      .flip-card-front {
        background-color: #f0f0f0; /* Ajuste conforme necessário */
      }
      
      .flip-card-back {
        background-color: #ffffff; /* Ajuste conforme necessário */
        transform: rotateY(180deg);
      }

      .custom-carousel .p-carousel-prev,
      .custom-carousel .p-carousel-next {
        display: none;
      }

      .team-container-cards {
        width: 90%;
      }
}
