@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@media (max-width: 767px) {
  .home {
    width: 90%;
    height: 750px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-image: url('../images/bg_home_mobile.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%; */
    /* border: 2px solid red; */
      /* background-color: #f2f5d3; */
   background-color: #f8f8f8;
    
  }

  .home-img-div {
    width: 100%;
    /* border: 2px solid red; */
  }
  
  .home-img {
    /* border: 2px solid green; */
    width: 100%;
    /* margin-bottom: -20%; */
  }

  .text-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-top: 40%;
    margin-bottom: 20%;
    /* margin-left: 5%;
    margin-top: 40%; */
    /* border: 2px solid blue; */
  }

  .name {
    font-family: "Lato", sans-serif;
    font-size: 2em;
    font-weight: 700;
    margin: 0;
    color: #9faeff;
    text-shadow: 1.5px 1.5px 0px rgba(0, 0, 0, 1);
    /* margin-top: -100px; */
    letter-spacing: 4px;
  }
  
  .typed-text {
    /* font-size: 1em; */
    color: #423baf;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    text-shadow: 0.3px 0.2px 1px rgba(0, 0, 0, 1);
  }
  /* .home-mobile-btn-div {
    margin-bottom: 10px;
  } */
  .home-mobile-btn {
    padding: 20px 75px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
  }
  
  .btn-43 {
    border: 2px solid rgb(0, 0, 0);
    z-index: 1;
    color: #000000;
  }
  
  .btn-43:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    background: #92e3a9;
    transition: all 0.3s ease;
  }
  
  .btn-43:hover {
    color: rgb(0, 0, 0);
  }
  
  .btn-43:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
  }

  .home-mobile-btn-div {
    /* border: 2px solid red; */
    margin: 10% auto -70% auto;
  }

}

@media (min-width: 1025px) {

.home {
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-image: url('../images/bg_home2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  transition: opacity 0.5s ease-in-out;
  opacity: 0; */
  /* background-color: #f2f5d3; */
  background-color: #f8f8f8;
}

.home-img-div {
  width: 50%;
  /* border: 2px solid red; */
}

.home-img {
  /* border: 2px solid green; */
  width: 80%;
}

.text-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 5%;
  width: 50%;
  /* border: 2px solid blue; */
}

.name {
  font-family: "Lato", sans-serif;
  font-size: 4.5em;
  font-weight: 700;
  margin: 0;
  color: #9faeff;
  text-shadow: 6px 4px 0px rgba(0, 0, 0, 1);
  margin-top: -100px;
  letter-spacing: 4px;
}

.typed-text {
  font-size: 2em;
  color: #423baf;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 1.5px 2px 0px rgba(0, 0, 0, 1);
}

.home-mobile-btn-div {
  display: none;
}
}