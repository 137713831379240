@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&family=Montserrat:wght@300&family=PT+Sans&display=swap');
@import url('https://fonts.cdnfonts.com/css/garet');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

@media screen and (max-width: 768px){
  .toolbar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100%;
  }

  .toolbar-components {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
  }

  .iconDrawer {
    align-self: flex-start !important;
    justify-self: flex-start !important;
    color: #000000;
  }

  .logo-img {
    width: 60px;
    margin-bottom: 0;
    border-radius: 50%;
    margin-top: 10px;
  }

  .btn41-43 {
    display: none;
  }

  .header.hidden {
    display: none !important;
  }

}

@media screen and (min-width: 769px) {

  .header {
    margin: 0 auto !important;
  }

  .header.hidden {
    display: none !important;
  }

  .toolbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-img {
    width: 60px;
    margin-bottom: 0;
    margin-right: 20px;
    border-radius: 50%;
  }

  .menu-item {
    padding: 20px;
    color: #000000;
    font-family: "Lato", sans-serif !important;
    font-size: 15px !important;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
    letter-spacing: 1px !important;
  }

  .menu-item:hover {
    color: #000000;
    background-color: rgba(146, 227, 169, 0.2);
  }

  .desktop-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

.btn41-43 {
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 2px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-43 {
  border: 2px solid rgb(0, 0, 0);
  z-index: 1;
  color: #000000;
}

.btn-43:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  background: #92e3a9;
  transition: all 0.3s ease;
}

.btn-43:hover {
  color: rgb(0, 0, 0);
}

.btn-43:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
}

.navigation__checkbox {
  display: none;
}

.navigation__button {
  position: relative;
  text-align: center;
  border-radius: 50%;
  z-index: 50;
  cursor: pointer;
}

.navigation__background {
  position: fixed;
  top: 1rem;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #9faeff;
  background-position: center;
  z-index: 40;
  visibility: hidden;
  transition: transform 800ms cubic-bezier(0.86, 0, 0.07, 1), opacity 800ms cubic-bezier(0.86, 0, 0.07, 1), visibility 800ms cubic-bezier(0.86, 0, 0.07, 1);
}

.navigation__nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  opacity: 0;
  width: 0;
  visibility: hidden;
  z-index: 50;
  transition: width 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55), visibility 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation__list {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  list-style: none;
}

.navigation__item {
  cursor: pointer;
  padding: 15px;
  color: #000000;
  font-family: "Lato", sans-serif !important;
  font-size: 15px !important;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
  letter-spacing: 1px !important;
}

.navigation__item:hover {
  color: #000000;
  background-color: rgba(146, 227, 169, 0.2);
}

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(100);
  opacity: 1;
  visibility: visible;
}

.navigation__checkbox:checked ~ .navigation__nav {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.navigation__icon {
  position: relative;
  transition: background-color 0.3s ease;
}

.navigation__icon, .navigation__icon::before, .navigation__icon::after {
  width: 2rem;
  height: 2px;
  background-color: #000;
  display: inline-block;
  transition: all .3s ease;
}

@media screen and (min-width: 768px) {
  .navigation__icon, .navigation__icon::before, .navigation__icon::after {
    width: 3rem;
  }
}

.navigation__icon::before, .navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
}

.navigation__icon::before {
  top: -0.7rem;
}

.navigation__icon::after {
  top: 0.7rem;
}

.navigation__button:hover .navigation__icon::before {
  top: -1rem;
}

.navigation__button:hover .navigation__icon::after {
  top: 1rem;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(45deg);
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-45deg);
}

.navigation__button {
  pointer-events: auto;
  z-index: 100;
}
