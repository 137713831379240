.App {
    width: 100%;
    height: 100%;
    margin: auto auto;
    /* border: 2px solid green; */
    justify-content: center;
    align-items: center;
    /* background-color: #f8f8f8; */
    position: relative;
}

.app-content {
	min-height: 100%;
	width: 100%;
    /* height: 500px; */
	padding-top: 0;
	position: relative;
	z-index: 1;
    margin-bottom: 380px;
    /* height: 1000px; */
	-webkit-box-shadow: #000 0 7px 35px 0;
	-moz-box-shadow: #000 0 7px 35px 0;
	box-shadow: #000 0 7px 35px 0;
    background-color: #f8f8f8;
  
}
.app-footer {
	padding: 0;
    color: white;
	background-color: black;
	position: fixed;
	z-index: -1;
    width: 100%;
    height: auto;
    height: fit-content;
    bottom: 0;
}

@media (max-width: 767px) {
    .app-content {
        min-height: 100%;
        width: 100%;
        /* height: 500px; */
        padding-top: 0;
        position: relative;
        margin-bottom: 620px;
        /* height: 1000px; */
        -webkit-box-shadow: #000 0 7px 35px 0;
        -moz-box-shadow: #000 0 7px 35px 0;
        box-shadow: #000 0 7px 35px 0;
        background-color: #f8f8f8;
    }
    .app-footer {
        padding: 0;
        color: white;
        background-color: black;
        position: fixed;
        z-index: -10;
        bottom: 0;
        height: 620px;
    }
}