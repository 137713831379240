@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@media screen and (max-width: 768px){
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 15%;
    }
    
    .footer-content {
        display: flex;
        flex-direction: column;
        width: 80%;
        justify-content: center;
        align-items: flex-start;
    }
    
    .menu-footer, .contatos-footer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .menu-footer hr, .contatos-footer hr {
        width: 50%;
        border: 1px solid #8AAABD;
        margin: 0;
        align-self: flex-start;
    }
    
    .menu-footer ul, .contatos-footer ul {
        list-style: none;
        padding: 0;
    }
    
    .menu-footer li, .contatos-footer li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        text-align: left;
        color: #ffffff;
        line-height: 2.5em;
        letter-spacing: 1.5px;
        font-size: 15px;
    }

    .contatos-footer ul li a {
        text-decoration: none;
        color: inherit;
    }

    .contatos-footer :nth-last-child(1) {
        line-height: 1.5em;
    }
    
    .h1-menu-footer, .h1-contatos-footer {
        font-size: 2.2em;
        font-family: "Hanken Grotesk", sans-serif;
        color: #ffffff;
        letter-spacing: 1px;
    }
    
    .contato-icone {
        color: rgba(255, 255, 255, 0.54) !important;
        cursor: default;
    }
    
    .link-footer {
        cursor: pointer;
    }
    
    .footer-bar {
        height: 40px;
        width: 100%;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #000000;
        text-align: center;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
        line-height: 1.4em;
    }

    .endereco {
        font-size: 14px;
    }
}

@media screen and (min-width: 769px) {
    .footer {
        padding-top: 50px;
        display: flex;
        flex: row;
        justify-content: flex-start;
        align-items: flex-end;
        background-color: #000000;
        height: 380px;
    }
    
    .footer-content {
        display: flex;
        flex-direction: row;
        width: 80%;
        margin: 0 auto;
        justify-content: center;
        align-items: flex-start;
    }
    
    .menu-footer, .contatos-footer{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .menu-footer hr, .contatos-footer hr {
        width: 35%;
        border: 1px solid #9faeff;
        margin: 10px 0;
        align-self: flex-start;
    }
    
    .menu-footer ul, .contatos-footer ul {
        list-style: none;
        padding: 0;
    }
    
    .menu-footer li, .contatos-footer li {
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        text-align: left;
        color: #ffffff;
        line-height: 2em;
        letter-spacing: 2px;
    }

    .contatos-footer ul li a {
        text-decoration: none;
        color: inherit;
    }
    
    .h1-menu-footer, .h1-contatos-footer {
        font-size: 2em;
        font-family: "Hanken Grotesk", sans-serif;
        color: #ffffff;
        letter-spacing: 2px;
    }
        
    .contato-icone {
        margin-top: 3px !important;
        color: rgba(255, 255, 255, 0.54) !important;
        cursor: default;
    }
    
    .link-footer {
        cursor: pointer;
    }
    
    .footer-bar {
        height: 40px;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #000000;
        text-align: center;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
        line-height: 1.4em;
    }

    .endereco {
        font-size: 12px;
    }
    
}
