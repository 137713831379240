@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

@media (max-width: 1024px) {
  .portfolio {
    padding-bottom: 20px;
  }
  
  .portfolio-h1 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    color: #9faeff;
    text-shadow: 2px 1px 0px rgba(0, 0, 0, 1);
    letter-spacing: 4px;
    width: 100%;
    text-align: center;
  }
  
  .projects {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .project-1,
  .project-2 {
    flex: 1 1 calc(50% - 20px);
    display: flex;
  }
  
  .desktop-image,
  .mobile-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .desktop-image-1 {
    width: 250px;
  }
  
  .mobile-image-1 {
    width: 90px;
  }
  
  .desktop-image-2 {
    width: 250px;
  }
  
  .mobile-image-2 {
    width: 90px;
  }
  
  .lg-backdrop, .lg-outer {
    z-index: 9999 !important;
  }

  .menu {
    z-index: 10;
  }
  
  .portfolio-img-container {
    display: none;
  }
}

@media (min-width: 1025px) {
  .portfolio {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .portfolio-h1 {
    font-family: "Lato", sans-serif;
    font-size: 3.5em;
    font-weight: 700;
    color: #9faeff;
    text-shadow: 3px 2px 0px rgba(0, 0, 0, 1);
    letter-spacing: 4px;
    width: fit-content;
    width: 100%;
    text-align: center;
  }

  .portfolio-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .desktop-image-1, .desktop-image-2 {
    width: 350px;
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .mobile-image-1, .mobile-image-2 {
    width: 150px;
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .desktop-image-1:hover, .desktop-image-2:hover {
    transform: scale(1.1);
  }

  .mobile-image-1:hover, .mobile-image-2:hover {
    transform: scale(1.1);
  }

  .portfolio-img-container {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .portfolio-avatar {
    width: 60%;
  }
}
